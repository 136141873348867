import { motion } from "framer-motion";
import type { User } from "lucia";
import { useState } from "react";
import { Eye, Home, Hourglass, Paintbrush, Scan, TreePalm, Warehouse, Waves } from 'lucide-react'

export default function AnimatedButton() {
    const [isHovered, setIsHovered] = useState(false)

    const icons = [
        {
            "name": "beachside",
            "icon": <TreePalm className="group-hover:scale-150 transition-all duration-300" />
        },
        {
            "name": "home",
            "icon": <Home className="group-hover:scale-150 transition-all duration-300" />
        },
        {
            "name": "goodview",
            "icon": <Eye className="group-hover:scale-150 transition-all duration-300" />
        },
        {
            "name": "design",
            "icon": <Paintbrush className="group-hover:scale-150 transition-all duration-300" />
        },
        {
            "name": "pool",
            "icon": <Waves className="group-hover:scale-150 transition-all duration-300" />
        },
        {
            "name": "tiny",
            "icon": <Scan className="group-hover:scale-150 transition-all duration-300" />
        },
        {
            "name": "historic",
            "icon": <Hourglass className="group-hover:scale-150 transition-all duration-300" />
        },
        {
            "name": "countryside",
            "icon": <Warehouse className="group-hover:scale-150 transition-all duration-300" />
        },
    ]


    return (
        <div className="w-[50vw] overflow-hidden" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <motion.div
                initial={false}
                animate={{ y: isHovered ? "-100%" : 0 }}
                transition={{ duration: 0.5, type: "tween", ease: "circOut" }}
            >
                <div
                    className="bg-[#211924] text-white flex justify-center items-center text-4xl rounded-none h-[20vh] outline-dashed hover:outline-dotted outline-2 outline-[#FBDAE2] -outline-offset-2 w-full"
                >
                    Book Now
                </div>
            </motion.div>
            <motion.div
                animate={{ y: isHovered ? "-100%" : 0 }}
                transition={{ duration: 0.5, type: "tween", ease: "circOut" }}
            >
                <a
                    href="#roomsection"
                    className="bg-[#211924] text-white flex justify-center items-center text-2xl rounded-none h-[20vh] outline-dotted hover:outline-dotted outline-2 outline-[#FBDAE2] -outline-offset-2 w-full"
                >

                    <div className="w-full h-full grid grid-cols-4 grid-rows-2">
                        {icons.map((data, i) => (
                            <div key={i} className="w-full h-full flex flex-col gap-2 justify-center items-center group">
                                {data.icon}
                                <p className="text-sm">{data.name}</p>
                            </div>
                        ))}
                    </div>
                </a>
            </motion.div>
        </div >
    )
}